import styles from 'libs/common/styles/pages/Home.module.scss';
import WithNavigation, {
	WithNavigationProps,
	WithNavigationState,
} from '../src/components/navigation/WithNavigation/WithNavigation';
import { withRouter } from 'next/router';
import { GetServerSideProps } from 'next';
import { injectIntl } from 'react-intl';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import React from 'react';
import { WithRouterProps } from 'next/dist/client/with-router';
import DashboardCards from '../src/components/DashboardCards/DashboardCards';

interface HomeProps extends WithNavigationProps, WithRouterProps, WithIntlComponentProps {}

interface HomeState extends WithNavigationState {
	modalOpen: boolean;
}

class Home extends WithNavigation<HomeProps, HomeState> {
	constructor(props: HomeProps) {
		super(props);
		this.breadcrumbList = [];
	}

	state = {
		modalOpen: false,
	};

	toggleModal = () => {
		this.setState(({ modalOpen }) => ({ modalOpen: !modalOpen }));
	};

	render(): JSX.Element {
		return this.renderWithNavs(
			<div className={styles.container}>
				<DashboardCards />
			</div>,
		);
	}
}

export const getServerSideProps: GetServerSideProps = async (context) => {
	return {
		props: {
			locale: context.locale,
		},
	};
};

export default withRouter<HomeProps>(injectIntl(Home));
