import { WithNavigationProps } from '../navigation/WithNavigation/WithNavigation';
import { WithIntlComponentProps } from 'libs/common/src/utils/i18n';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { WithRouterProps } from 'next/dist/client/with-router';
import moment from 'moment';
import { faDatabase, faUtensils } from '@fortawesome/free-solid-svg-icons';
import DashboardCard from '../widgets/DashboardCard/DashboardCard';
import { injectIntl } from 'react-intl';
import { OrsysContext, OrsysContextProviderValues } from 'libs/common/src/utils/OrsysContext';
import { SettlementSearchDTO } from 'libs/common/src/models/SettlementModels';
import { apiPost, CONTENT_TYPES } from 'libs/common/src/utils/ApiUtils';
import { DeliveryOrderMinimalReadDTO, LocalOrderMinimalReadDTO } from 'libs/common/src/models/order/OrderModels';
import { DeliveryOrderStatus, LocalOrderStatus } from 'libs/common/src/models/enum/Order';
import { formatCurrency } from 'libs/common/src/utils/NumberUtils';
import { useQuery } from '@tanstack/react-query';
import { OrderStatWrapper } from 'libs/common/src/models/statistics/OrderStatModels';

interface DashboardCardsProps extends WithNavigationProps, WithRouterProps, WithIntlComponentProps {}

const DashboardCards: FC<DashboardCardsProps> = ({ intl }) => {
	const { restaurant } = useContext<OrsysContextProviderValues>(OrsysContext);

	const startDate = useMemo(() => {
		return moment().format('yyyy-MM-DD');
	}, []);

	const endDate = useMemo(() => {
		return moment().format('yyyy-MM-DD');
	}, []);

	const trafficDataQuery = useQuery<OrderStatWrapper>({
		queryKey: ['statistics', 'traffic', 'total', restaurant?.id, startDate, endDate],
		queryFn: async () => {
			const dateRange = {
				start: startDate,
				end: endDate,
			};

			const { data } = await apiPost<OrderStatWrapper>(
				`/statistics/by-restaurant/${restaurant?.id}/orders/by-date`,
				JSON.stringify(dateRange),
				CONTENT_TYPES.JSON,
			);

			return data;
		},
		enabled: restaurant != null && startDate != null && endDate != null,

		refetchOnWindowFocus: false,
		refetchOnReconnect: false,
	});

	const dailyOrdersCount = useMemo<null | number>(() => {
		let result = 0;
		try {
			const localOrders = trafficDataQuery.data?.localOrders;
			const deliveryOrders = trafficDataQuery.data?.deliveryOrders;
			if ((localOrders?.length || 0) > 0) {
				result += (localOrders || [])[0]?.orderCount || 0;
			}
			if ((deliveryOrders?.length || 0) > 0) {
				result += (deliveryOrders || [])[0]?.orderCount || 0;
			}
		} catch (e) {
			console.error(e);
		}
		return result;
	}, [trafficDataQuery?.data]);

	const dailyOrdersSum = useMemo<null | number>(() => {
		let result = 0;
		try {
			const localOrders = trafficDataQuery.data?.localOrders;
			const deliveryOrders = trafficDataQuery.data?.deliveryOrders;
			if ((localOrders?.length || 0) > 0) {
				result += (localOrders || [])[0]?.grandTotal || 0;
			}
			if ((deliveryOrders?.length || 0) > 0) {
				result += (deliveryOrders || [])[0]?.grandTotal || 0;
			}
		} catch (e) {
			console.error(e);
		}
		return result;
	}, [trafficDataQuery?.data]);

	if (restaurant == null) {
		return <></>;
	}

	return (
		<>
			<DashboardCard
				type={'default'}
				value={
					<>
						{dailyOrdersCount} <span>{intl.formatMessage({ id: 'general.piece' })}</span>
					</>
				}
				iconPosition={'40px'}
				iconSize={'70px'}
				title={intl.formatMessage({ id: 'pages.dashboard.order' })}
				loading={trafficDataQuery.data == null}
				icon={faUtensils}>
				{intl.formatMessage({ id: 'pages.dashboard.order-count' })}
			</DashboardCard>
			<DashboardCard
				type={'warning'}
				value={
					<>
						{(dailyOrdersSum || 0).toFixed(0)} <span>Ft</span>
					</>
				}
				iconPosition={'40px'}
				iconSize={'70px'}
				title={intl.formatMessage({ id: 'sidebar.statistics.traffic' })}
				loading={trafficDataQuery.data == null}
				icon={faDatabase}>
				{intl.formatMessage({ id: 'pages.dashboard.order-traffic' })}
			</DashboardCard>
		</>
	);
};

export default injectIntl(DashboardCards);
