import React, { CSSProperties, FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import styles from "./DashboardCard.module.scss";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as icons from "@fortawesome/free-solid-svg-icons";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

interface DashboardCardProps extends PropsWithChildren {
    icon?: IconProp
    type: "default" | "warning" | "danger" | "success" | "info"
    iconType?: "faCheck" | "faClock" | "faCreditCard" | "faMoneyCheckAlt" | "faMoneyBillWave" | "faTimes" | "faProjectDiagram" | "faCalculator" | "faHourglassHalf" | "fa"
    title?: string | ReactNode
    iconSize?: number | string
    iconPosition?: number | string
    className?: string
    style?: CSSProperties
    value: ReactNode | string
    loading?: boolean
}

const DashboardCard: FunctionComponent<DashboardCardProps> = (props) => {
    const {loading, title, children, className, style, type, icon, iconPosition, iconSize, iconType, value} = props;

    let iconStyle: CSSProperties = {};
    if (iconPosition) {
        iconStyle.right = iconPosition;
    }
    if (iconSize) {
        iconStyle.fontSize = iconSize;
    }
    // @ts-ignore
    return (
        <div
            className={`${styles.dashboardCard} ${styles[type]} ${iconType ? styles[iconType] : ""} ${className ? className : ""}`}
            style={style}>
            <div className={styles.value}>
                {value}
            </div>
            {title && (
                <div className={`${styles.titleContainer}`}>
                    {title}
                </div>
            )}
            <div className={styles.content}>
                {children}
            </div>
            {loading && (
                <FontAwesomeIcon icon={faSpinner} spin style={iconStyle}/>
            )}
            {(iconType || icon) && !loading && (
                // @ts-ignore
                <FontAwesomeIcon icon={icon ? icon : icons[iconType]}
                                 style={iconStyle}/>
            )}
        </div>
    );
}

export default DashboardCard;
